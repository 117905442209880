@import "../../assets/css/variables.scss";

.videos-layout-home {
    height: 100%;

    &-hosting {
        &-heading {
            font-size: 70px;
            font-weight: 900;
            line-height: 75px;
            font-family: var(--hms-ui-fonts-heading);
            color: var(--hms-ui-colors-white);
            text-align: center;
            margin-bottom: 24px;

            @media (max-width: 1024px) {
                font-size: 64px;
                line-height: 65px;
            }

            @media (max-width: 767px) {
                font-size: 40px;
                line-height: 50px;

                span {
                    display: block;
                }
            }
        }

        &-text {
            font-size: 30px;
            font-weight: 700;
            text-align: center;
            color: var(--hms-ui-colors-light);

            @media (max-width: 1024px) {
                font-size: 24px;
            }

            @media (max-width: 767px) {
                font-size: 16px;

                br {
                    display: none;
                }
            }
        }

        &-uploadBox {
            max-width: 450px;
            margin: 80px auto 20px;

            @media (max-width: 767px) {
                margin: 60px auto 20px;
            }
        }
    }
}