@import "../../assets/css/variables.scss";

.videos-player {
    &-caption-list {
        display: none;
        position: absolute;
        bottom: calc(100% + 20px);
        right: 20px;
        z-index: 1;
        background-color: var(--hms-ui-colors-surface_bright);
        color: var(--hms-ui-colors-lighter);
        padding: 2px 0;
        @include vendor-prefix(border-radius, 6px);
        font-size: 14px;
        max-width: 660px;
        overflow-y: auto;
        min-width: 160px;
        max-height: 250px;

        @media(max-width:767px) {
            bottom: 100%;
            max-height: 120px;
            right: 0;
            font-size: 13px;
        }

        &.visible {
            display: block;
        }

        &-label {
            padding: 5px 10px;
            cursor: pointer;
            display: flex;
            gap: 5px;
            align-items: center;

            &-text {
                text-align: left;
            }

            &-short {
                text-transform: uppercase;
                background-color: var(--hms-ui-colors-black);
                padding: 1px 5px;
                margin-left: auto;
                font-size: 13px;
                border: 1px solid var(--hms-ui-colors-surface_bright);
                border-radius: 4px;
                min-width: 17px;
                text-align: center;
                flex-shrink: 0;
            }

            &:hover,
            &.active {
                background-color: var(--hms-ui-colors-black);
            }
        }

        &-languages {
            column-count: 3;
            column-gap: 20px;

            @media(max-width:767px) {
                column-count: 2;
                column-gap: 15px;
            }

        }
    }
}