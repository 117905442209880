@import "../../assets/css/variables.scss";

.videos-layout-video {
    &-detail {
        margin: 20px 0;
        width: 100%;

        &-mediaMsg {
            padding: 50px 0px;
            text-align: center;
            color: var(--hms-ui-colors-light);
            font-size: 14px;

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-weight: 600;
        }

        &-title {
            font-size: 22px;
            font-weight: 600;
            color: var(--hms-ui-colors-lighter);
            margin-bottom: 15px;
            word-break: break-all;

            @media(max-width:767px) {
                font-size: 18px;
            }
        }

        &-statics {
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 20px;
            gap: 15px;

            @media(max-width:767px) {
                flex-direction: column;
            }

            &-creator {
                margin: auto 0 auto 0;

                @media(max-width:767px) {
                    width: 100%;
                }
            }

            &-buttons {
                margin: auto 0 auto auto;
                display: flex;
                gap: 10px;
                align-items: center;

                @media(max-width:767px) {
                    width: 100%;
                }
            }

        }

        &-views {
            font-size: 14px;
            font-weight: 600;
            color: var(--hms-ui-colors-secondary);
            margin-bottom: 10px;

            @media(max-width:767px) {
                font-size: 13px;
            }
        }

        &-desc {
            font-size: 15px;
            color: var(--hms-ui-colors-secondary);

            @media(max-width:767px) {
                font-size: 14px;
            }
        }

        &-divider {
            margin: 23px 0;
            background-color: var(--hms-ui-colors-surface_dim);
            height: 1px;
        }
    }
}