@import "../../assets/css/variables.scss";

.videos-layout-list {
    &-uploadBox {
        width: 100%;
        margin-bottom: 24px;
    }

    &-items {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;
        margin-right: -12px;

        @media(max-width:767px) {
            justify-content: center;
        }

        &-box {
            width: 33.33%;
            padding-left: 12px;
            padding-right: 12px;
            margin-bottom: 24px;
            min-width: 290px;
            max-width: 375px;

            @media (max-width:900px) {
                width: 50%;
            }

            @media (max-width:767px) {
                width: 100%;
            }


            &-inner {
                color: var(--hms-ui-colors-white);
                @include vendor-prefix(border-radius, 11px);
                position: relative;
                background: var(--hms-ui-colors-surface_bright);
                width: 100%;
                border: 1px solid var(--hms-ui-colors-surface_bright);
                overflow: hidden;
                min-height: 100px;
            }

            &-media {
                height: 145px;
                width: 100%;
                overflow: hidden;
                position: relative;
                display: flex;

                &-process {
                    padding: 15px;
                    width: 100%;
                    margin: auto;

                    &-text {
                        text-align: center;
                        color: var(--hms-ui-colors-light);
                        font-size: 14px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                    }

                    &-line {
                        height: 4px;
                        @include vendor-prefix(border-radius, 6px);
                        background: var(--hms-ui-colors-secondary);
                        overflow: hidden;
                        margin-top: 10px;

                        &-inner {
                            height: 100%;
                            background: var(--hms-ui-colors-yellow);
                            width: 0;
                        }
                    }
                }

                &-action {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    padding: 12px;
                    display: flex;

                    &-indicate {
                        padding: 1px 4px;
                        font-size: 14px;
                        @include vendor-prefix(border-radius, 4px);
                        margin: 0px 0px auto 0;
                        color: var(--hms-ui-colors-danger);
                        background: rgba(19, 19, 19, 0.8);
                    }

                    &-views {
                        padding: 1px 4px;
                        font-size: 14px;
                        @include vendor-prefix(border-radius, 4px);
                        background: rgba(19, 19, 19, 0.8);
                        color: var(--hms-ui-colors-lighter);
                        margin: 0px 0px auto auto;
                    }

                }

                img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    min-height: 100%;
                    object-fit: cover;
                    @include vendor-prefix(border-radius, 5px 5px 0px 0px);
                }
            }

            &-content {
                width: 100%;
                padding: 15px 15px;
                min-height: 92px;
            }

            &-title {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 10px;
                max-width: 100%;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: var(--hms-ui-colors-white);
                display: inline-block;

                &:hover {
                    color: var(--hms-ui-colors-yellow);
                }

                @media (max-width: 992px) {
                    font-size: 14px;
                }
            }

            &-copy {
                display: flex;
                font-size: 14px;
                gap: 10px;

                &-url {
                    width: 100%;
                    color: var(--hms-ui-colors-yellow);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &-btn {
                    color: var(--hms-ui-colors-secondary);
                    white-space: nowrap;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    &:hover {
                        color: var(--hms-ui-colors-yellow);

                        svg {
                            path {
                                &[stroke] {
                                    stroke: var(--hms-ui-colors-yellow);
                                }

                                &[fill] {
                                    fill: var(--hms-ui-colors-yellow);
                                }
                            }
                        }
                    }
                }
            }

            &-label {
                display: flex;
                font-size: 14px;
                gap: 10px;

            }


            &-bottom {
                border-top: 1px solid var(--hms-ui-colors-surface_bright);
                background-color: var(--hms-ui-colors-surface_dim);
                color: var(--hms-ui-colors-light);
                font-size: 14px;
                height: 48px;

                &-btn {
                    font-size: 14px !important;
                    color: var(--hms-ui-colors-light);
                    white-space: nowrap;
                    text-transform: none !important;
                    overflow: hidden;
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;

                    &:not(:last-child) {
                        &::after {
                            content: "";
                            height: 55%;
                            width: 1px;
                            background-color: var(--hms-ui-colors-secondary_default);
                            position: absolute;
                            right: 0px;
                        }
                    }

                    &:hover,
                    &.active {
                        color: var(--hms-ui-colors-yellow);

                        svg {
                            path {
                                &[stroke] {
                                    stroke: var(--hms-ui-colors-yellow);
                                }

                                &[fill] {
                                    fill: var(--hms-ui-colors-yellow);
                                }
                            }
                        }
                    }

                    &:disabled {
                        opacity: 0.5;
                        color: inherit !important;

                    }
                }

                &-dropdown {
                    &-btn {
                        font-size: 14px !important;
                        padding-top: 10px !important;
                        padding-bottom: 10px !important;
                        color: #C0C0C0 !important;

                        svg {
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }

    &-loadMore {
        width: 100%;
        padding-top: 70px;
        padding-bottom: 70px;
    }
}