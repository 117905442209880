:root {
  --bs-body-font-family: "Roboto", sans-serif;
  --hms-ui-fonts-heading: "Playfair Display", serif;
  --hms-ui-colors-yellow: #e3c050;
  --hms-ui-colors-secondary: #8c8e95;
  --hms-ui-colors-secondary_default: #373737;
  --hms-ui-colors-surface_bright: #2b2b2b;
  --hms-ui-colors-surface_dim: #1f1f1f;
  --hms-ui-colors-surfaceLight: #131313;
  --hms-ui-colors-surfaceLighter: #454648;
  --hms-ui-colors-light: #c0c0c0;
  --hms-ui-colors-lighter: #e9e9e9;
  --hms-ui-colors-white: #fff;
  --hms-ui-colors-black: #000;
  --hms-ui-colors-gray: #575757;
  --hms-ui-colors-danger: #FB1751;
  --hms-ui-colors-dark: #1e1e1e;
  --hms-ui-colors-dark-light: #3f3f41;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--hms-ui-colors-black);
  color: var(--hms-ui-colors-light);
}