@import '../../assets/css/variables.scss';

.videos-layout {
    &-header {
        position: fixed;
        left: 0;
        right: 0;
        background: #1F1F1F;
        border-bottom: 1px solid #2B2B2B;
        top: 0;
        z-index: 999;

        &-inner {
            padding: 8px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 67px;

            @media(max-width:767px) {
                padding: 8px 8px;
                font-size: 13px;
            }
        }


        &-left {
            gap: 10px;
            display: flex;
            align-items: center;

            @media(max-width:767px) {
                gap: 5px;
            }
        }

        &-menuButton {
            padding: 0;
            cursor: pointer;

            &:hover {
                svg {
                    path {
                        &[stroke] {
                            stroke: #fff;
                        }

                        &[fill] {
                            fill: #fff;
                        }

                    }
                }
            }
        }

        &-lineV {
            width: 1px;
            height: 24px;
            background-color: #373737;
        }

        &-logo {
            padding: 0px 5px;
            display: flex;

            &-img {
                max-width: 100%;
                height: 40px;
                max-height: 100%;
            }

            &-text {
                font-size: 13px;
                color: var(--hms-ui-colors-yellow);
                margin: auto -5px 0px -15px;
                padding-right: 5px;
            }
        }

        &-right {
            display: flex;
            align-items: center;
            gap: 10px;

            @media(max-width:767px) {
                gap: 5px;
            }

            &-item {
                position: relative;

                &-btn {
                    cursor: pointer;
                    color: #C0C0C0;
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 125%;
                    text-align: center;
                    width: 48px;
                    height: 48px;
                    @include vendor-prefix(border-radius, 8px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: 1px solid transparent;

                    @media(max-width:767px) {
                        width: 45px;
                        height: 45px;
                    }

                    svg {
                        margin: auto auto 0;
                    }

                    &-text {
                        margin-top: 5px;
                        margin-bottom: auto;
                        text-transform: uppercase;
                        overflow: hidden;
                        width: 100%;
                        white-space: nowrap;
                    }


                    &.active,
                    &:hover {
                        border-color: #e3c050;
                        background-color: #1f1f1f;
                        color: #e9e9e9;

                        svg path {
                            &[fill] {
                                fill: #e9e9e9;
                            }

                            &[stroke] {
                                stroke: #e9e9e9;
                            }
                        }
                    }

                    &-user {
                        width: 30px;
                        height: 30px;
                        @include vendor-prefix(border-radius, 50px);
                        overflow: hidden;
                        margin: auto;
                        display: flex;
                        background-color: #2B2B2B;

                        svg {
                            margin: auto;
                        }

                        img {
                            object-fit: cover;
                        }
                    }
                }

                &-dropdown {
                    .MuiPaper-root {
                        @include vendor-prefix(border-radius, 11px);
                        border: 1px solid #2b2b2b;
                        background: #1f1f1f;
                        box-shadow: 0px 4px 50px 0px #131313;
                        top: 77px !important;
                        min-width: 200px;
                        max-width: 90%;
                        max-height: calc(100% - 120px);
                    }

                    &-space {
                        .MuiPaper-root {
                            top: 110px !important;
                        }
                    }

                    &-language {
                        &-header {
                            color: #575757;
                            padding: 8px 8px;
                            font-size: 15px;
                        }

                        .MuiPaper-root {
                            width: 500px;
                            padding: 0px 8px;
                        }

                        &-inner {
                            display: flex;
                            flex-wrap: wrap;
                        }



                        &-item {
                            color: #C0C0C0 !important;
                            font-size: 14px !important;
                            width: 33.33%;
                            @include vendor-prefix(border-radius, 8px !important);
                            padding: 4px !important;

                            &-check {
                                width: 15px;

                                svg {
                                    width: 10px;
                                    height: auto;
                                }
                            }

                            &.active {
                                color: #e3c050 !important;
                            }

                            @media(max-width:767px) {
                                font-size: 13px !important;
                            }
                        }
                    }

                    &-apps {

                        &-header {
                            padding: 12px 20px;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 135%;
                            color: #e9e9e9;
                            border-bottom: 1px solid #2B2B2B;
                        }

                        &-list {
                            padding-left: 8px !important;
                            padding-right: 8px !important;

                            &-item {
                                @include vendor-prefix(border-radius, 11px !important);
                                gap: 10px !important;

                                span {
                                    font-size: 16px;
                                    color: var(--hms-ui-colors-yellow);
                                }

                                p {
                                    font-size: 14px;
                                    color: var(--hms-ui-colors-secondary);
                                }

                                @media(max-width:767px) {
                                    gap: 5px;

                                    span {
                                        font-size: 15px;
                                    }

                                    p {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }

                    &-users {

                        &-header {
                            padding: 12px 20px;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 135%;
                            color: #e9e9e9;
                            border-bottom: 1px solid #2B2B2B;
                        }

                        &-list {
                            padding: 0px 8px !important;

                            &-divider {
                                height: 1px;
                                background-color: #2B2B2B;
                                margin: 8px -8px;
                            }

                            &-item {
                                @include vendor-prefix(border-radius, 11px !important);
                                gap: 10px !important;
                                font-size: 14px;
                                color: #C0C0C0;

                                span {
                                    font-size: 14px;
                                }

                                &:hover {
                                    color: #e9e9e9;
                                }
                            }
                        }
                    }


                }

                &-accounts {
                    padding-bottom: 50px;

                    &-btn {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        position: relative;
                        padding-right: 10px;
                        max-width: 230px;
                        cursor: pointer;
                        color: #C0C0C0;
                        font-size: 14px;
                        font-weight: 600;
                        border: 1px solid transparent;
                        height: 48px;
                        @include vendor-prefix(border-radius, 8px);
                        padding: 0px 10px;

                        @media(max-width:767px) {
                            padding: 0px 5px;
                            gap: 5px;
                        }

                        &-logo {
                            width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #373737;
                            border: 1px solid #303740;
                            @include vendor-prefix(border-radius, 50%);
                            overflow: hidden;
                            flex: none;

                            img {
                                height: auto;
                                max-width: 100%;
                                object-fit: cover;
                            }
                        }

                        &-name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media(max-width:767px) {
                                display: none;
                            }
                        }

                        &.active,
                        &:hover {
                            border-color: #e3c050;
                            background-color: #1f1f1f;
                            color: #e9e9e9;

                            svg path {
                                &[fill] {
                                    fill: #e9e9e9;
                                }

                                &[stroke] {
                                    stroke: #e9e9e9;
                                }
                            }
                        }
                    }

                    &-list {
                        padding: 0px 8px !important;
                        margin-bottom: 10px !important;

                        &-loader {
                            padding: 20px;
                            text-align: center;
                        }

                        &-item {
                            @include vendor-prefix(border-radius, 11px !important);
                            gap: 10px !important;
                            font-size: 14px;
                            color: #C0C0C0;
                            padding: 8px 10px !important;

                            &-logo {
                                width: 30px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: #373737;
                                border: 1px solid #575757;
                                @include vendor-prefix(border-radius, 50%);
                                overflow: hidden;
                                flex: none;

                                img {
                                    height: auto;
                                    max-width: 100%;
                                    object-fit: cover;
                                }
                            }

                            &-name {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &-check {
                                margin-left: auto;

                                svg {
                                    width: 14px;
                                    height: auto;
                                }
                            }

                            &:hover {
                                color: #e9e9e9;
                            }
                        }
                    }

                    &-footer {
                        padding: 12px 20px;
                        font-size: 16px;
                        font-weight: 700;
                        color: #C0C0C0;
                        border-top: 1px solid #2B2B2B;
                        position: sticky;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        cursor: pointer;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        right: 0;
                        justify-content: center;
                        gap: 5px;
                        background-color: #1F1F1F;

                        &:hover {
                            color: #e3c050;

                            svg path {
                                &[fill] {
                                    fill: #e3c050;
                                }

                                &[stroke] {
                                    stroke: #e3c050;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-sidebar {
        position: fixed;
        left: -100%;
        transition: 0.4s;
        width: 320px;
        height: calc(100% - 67px);
        background-color: #131313;
        z-index: 9999999;
        top: 67px;
        padding: 10px;

        &-space {
            top: 100px;
        }

        &.active {
            left: 0%;
        }

        &-inner {
            height: 100%;
            @include vendor-prefix(border-radius, 11px);
            border: 1px solid #2B2B2B;
            background: #1F1F1F;
            box-shadow: 0px 4px 50px 0px #131313;
        }

        &-header {
            padding: 16px;
            border-bottom: 1px solid #2B2B2B;
        }

        &-btn {
            @include vendor-prefix(border-radius, 6px);
            border: 1px solid #e3c050;
            background: #000;
            padding: 12px 36px 12px 30px;
            display: flex;
            padding: 11px 11px;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-weight: bold;
            gap: 8px;
            text-decoration: none;
            width: 100%;

            &:hover {
                color: #e3c050;

                svg path {
                    &[fill] {
                        fill: #e3c050
                    }

                    &[stroke] {
                        stroke: #e3c050
                    }
                }
            }
        }

        &-body {
            padding: 10px;
        }

        &-link {
            padding: 12px;
            color: #C0C0C0;
            font-size: 15px;
            line-height: 135%;
            display: flex;
            align-items: center;
            @include vendor-prefix(border-radius, 8px);

            &.active {
                background: #2B2B2B;
                color: #fff;
            }

            &:not(.active):hover {
                color: #e3c050;
            }
        }

        &-overlay {
            height: 100vh;
            position: fixed;
            z-index: 999999;
            cursor: pointer;
            width: 100%;
        }

    }


}