@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700;800&family=Roboto:wght@400;700&display=swap");

:root {
  --plyr-video-control-background-hover: #2b2b2b;
  --plyr-progress-marker-background: #e9e9e9;
  --off-white-color: #e9e9e9;
  --secondary-gray-color: #8c8e95;
  --yellow-color: #e3c050;

  --hms-ui-colors-darkGrey: #1f1f1f !important;
}

/* common classes */

.margin-left-auto {
  margin-left: auto;
}

.text-decoration-none {
  text-decoration: none;
}

.light-gray-bg {
  background-color: rgb(30, 30, 30);
}

.off-white-text {
  color: var(--off-white-color);
}

.secondary-gray-text {
  color: var(--secondary-gray-color);
}

.light-gray-text {
  color: rgb(30, 30, 30);
}

.white-color-text {
  color: #fff;
}

.yellow-color-text {
  color: var(--yellow-color) !important;
}

.charcol-color-text {
  color: var(--hms-ui-colors-gray);
}

.grey-color-text {
  color: #c0c0c0;
}

.yellow-color-bg {
  background-color: var(--yellow-color);
}

.dark-black-bg {
  background-color: #000000 !important;
}

.grey_color {
  background-color: var(--plyr-video-control-background-hover);
}

.dark_grey-color {
  background-color: var(--hms-ui-colors-darkGrey);
}

.display-block {
  display: block;
}

.display-none {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.com-heading {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 500;
}

.com-divider {
  height: 1px;
  width: 100%;
  background-color: var(--hms-ui-colors-darkGrey);
  margin: 23px 0;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.gap-4 {
  gap: 8px;
}

.gap-5 {
  gap: 10px;
}

.p-1 {
  padding: 2px;
}

.p-01 {
  padding: 4px;
}

.p-02 {
  padding: 8px;
}

.p-03 {
  padding: 12px;
}

.p-04 {
  padding: 16px;
}

.p-05 {
  padding: 20px;
}

.p-06 {
  padding: 24px;
}

.p-07 {
  padding: 28px;
}

.text-xs {
  font-size: 12px;
}

.text-sm {
  font-size: 14px;
}

.text-base {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

.text-xl {
  font-size: 20px;
}

.text-2xl {
  font-size: 24px;
}

.text-3xl {
  font-size: 30px;
}

.text-4xl {
  font-size: 40px;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.mx-3 {
  margin: 12px 0;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-7 {
  margin-top: 28px;
}

.mt-8 {
  margin-top: 32px;
}

.mt-9 {
  margin-top: 36px;
}

.mt-10 {
  margin-top: 44px;
}

.mt-11 {
  margin-top: 48px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 20px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-7 {
  margin-bottom: 28px;
}

.mb-8 {
  margin-bottom: 32px;
}

.mb-9 {
  margin-bottom: 36px;
}

.mb-10 {
  margin-bottom: 44px;
}

.mb-11 {
  margin-bottom: 48px;
}

.pb-5 {
  padding-bottom: 20px;
}

.pb-8 {
  padding-bottom: 32px;
}

.mx-4 {
  margin: 16px 0;
}

.radius-2 {
  border-radius: 5px;
}

.radius-4 {
  border-radius: 12px;
}

.border-bottom {
  border-bottom: 1px solid var(--plyr-video-control-background-hover);
}

.border-yellow {
  border: 1px solid var(--yellow-color) !important;
}

.border-grey {
  border: 1px solid var(--hms-ui-colors-secondary_default);
}

.border-none {
  border: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.outline-none {
  outline: none;
}

.bg-transparent {
  background: transparent;
}

.text-line-through {
  text-decoration: line-through;
}

.fixed {
  position: fixed;
}

.position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.h-100vh {
  height: 100vh;
}

.w-100vw {
  width: 100vw;
}

/* common classes end*/

.skiptranslate,
.goog-gt-tt {
  display: none !important;
}

font {
  background-color: transparent !important;
  box-shadow: none !important;
}

.front-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99999;
}

.loading {
  height: 0;
  width: 0;
  padding: 38px;
  border: 7px solid #ffffffde;
  border-right-color: transparent;
  border-radius: 155px;
  animation: loader 1s infinite linear;
}

.backdrop-fillter {
  background-color: rgba(19, 19, 19, 0.7);
  backdrop-filter: blur(2px);
}

@keyframes loader {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

p {
  font-size: 15px;
  color: var(--secondary-gray-color);
}

.main-layout {
  min-height: 100vh;
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  background-color: #000;
  box-sizing: border-box;
}

.plyr__progress__marker {
  width: 10px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #606060;
}

.video-wrap-body {
  /* display: flex;
  justify-content: center; */
  background-color: #000;
}

.video-wrap {
  position: relative;
  width: 100%;
}

.video-wrap video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 100vh;
}

.main-layout .video-wrap {
  width: auto;
}

.plyr__control--overlaid {
  background-color: #000 !important;
  border: 5px solid #acacac;
  height: 100px;
  width: 100px !important;
}

.plyr__control--overlaid use {
  color: #d9d9d9;
}

.plyr__control--overlaid svg {
  margin: auto;
  height: 36px;
  width: 36px;
}

.plyr--video {
  border-radius: 0;
}

.plyr--video .plyr__controls {
  padding: 0;
  height: 44px;
  background-color: #000;
}

.plyr__controls.hidden {
  display: none !important;
}

.plyr__progress input[type="range"] {
  color: #e9e9e9;
}

.plyr__progress__buffer {
  background: #2b2b2b;
}

.vid-player-wrap {
  position: relative;
  width: 860px;
  max-width: 94vw;
}

.click-for-sound {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 45px;
}

.click-for-sound-btn {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 12px;
  padding: 0 16px;
  height: 55px;
  border: none;
  border-radius: 40px;
  background-color: #131313 !important;
  color: #e9e9e9;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  min-width: 55px;
  box-sizing: border-box;
}

.click-for-sound-text {
  display: none;
}

.click-for-sound img {
  width: 23px;
}

.video-wrap:hover .click-for-sound-text {
  display: block;
}

.plyr__controls__item .plyr__control,
button.plyr__controls__item {
  height: 44px;
  width: 42px;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  color: #e9e9e9;
}

.plyr__controls__item .plyr__control:hover,
button.plyr__controls__item:hover {
  background-color: #2b2b2b !important;
}

/* .plyr__control[data-plyr="pip"], .plyr__control[data-plyr="captions"]{
  display: none !important;
} */
.plyr__controls__item.plyr__volume {
  position: relative;
}

.plyr__controls__item.plyr__volume input {
  display: none;
  position: absolute;
  background-color: #13131396;
  text-align: center;
  padding: 22px 12px;
  width: 120px;
  border-radius: 0;
  left: 50%;
  transform: rotate(-90deg) !important;
  -webkit-transform: rotate(-90deg) !important;
  -moz-transform: rotate(-90deg) !important;
  -o-transform: rotate(-90deg) !important;
  -ms-transform: rotate(-90deg) !important;
  transform-origin: left;
  bottom: 50%;
  top: -20px;
  width: 110px !important;
  max-width: none;
  margin: 0;
  color: #e9e9e9;
}

.plyr__controls__item.plyr__volume:hover input {
  display: block;
}

.plyr__controls__item.plyr__menu {
  position: static;
}

.plyr__menu__container {
  width: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) !important;
  -webkit-transform: translateX(-50%) !important;
  -moz-transform: translateX(-50%) !important;
  -o-transform: translateX(-50%) !important;
  -ms-transform: translateX(-50%) !important;
  margin: 0;
  bottom: 146px;
  border-radius: 11px;
  background-color: #131313;
  color: #8c8e95;
  font-size: 14px;
  padding: 4px;
}

.plyr__menu__container .plyr__control {
  width: 100%;
  margin: 0;
}

.speed-control-area {
  display: none;
  z-index: 1;
  border-radius: 11px;
  background-color: #131313;
  color: #8c8e95;
  font-size: 14px;
  padding: 4px;
  overflow: hidden;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  white-space: nowrap;
}

.speed-control-area.speed-control-area-animated {
  bottom: 150px;
}

.speed-control-area.visible {
  display: block;
}

.vid-light-text {
  color: #e9e9e9;
}

.speed-control-area .active {
  color: #e9e9e9 !important;
}

.speed-control-area-row {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.speed-control-area-num {
  margin-bottom: 4px;
}

.speed-control-area-num .x-btn.active {
  transform: scale(1.14);
  -webkit-transform: scale(1.14);
  -moz-transform: scale(1.14);
  -o-transform: scale(1.14);
  -ms-transform: scale(1.14);
  transform-origin: center;
}

.speed-control-area-num .x-btn {
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: inherit;
}

.speed-control-area-num .x-btn:hover {
  color: #fff !important;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
}

.speed-control-area-time {
  background-color: #2b2b2b;
  border-radius: 0 0 9px 9px;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: none;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid.visible {
  display: block !important;
  opacity: 1;
  visibility: visible;
}

.speed-control-area-animated .x-btn {
  display: none;
}

.speed-control-area-animated .x-btn.active {
  display: block;
}

.speed-control-area-animated .speed-control-area-num {
  width: fit-content;
  margin: 0 auto 4px;
}

.speed-control-area-animated .speed-control-area-num>svg {
  color: var(--yellow-color);
}

.speed-control-area-animated .speed-control-area-num:hover .x-btn {
  display: block;
}

.speed-control-area-animated .speed-control-area-num:hover .speed-icon {
  display: none;
}

.speed-control-area-animated .vid-light-text.updated {
  animation: upside 0.3s linear;
}

@keyframes upside {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.caption-list {
  display: none;
  position: absolute;
  bottom: calc(100% + 20px);
  right: 20px;
  z-index: 1;
  background-color: #2b2b2b;
  color: #e9e9e9;
  /* max-width: 270px; */
  min-width: 160px;
  max-height: 190px;
  /* overflow-y: hidden; */
  padding: 2px 0;
  border-radius: 6px;
  font-size: 16px;

  max-width: 660px;
  overflow-y: auto;
  max-height: 250px;
}

.caption-list.visible {
  display: block;
}

.caption-list-short-name {
  text-transform: uppercase;
  background-color: #000;
  padding: 1px 5px;
  margin-left: auto;
  font-size: 13px;
  border: 1px solid #2b2b2b;
  border-radius: 4px;
  min-width: 17px;
  text-align: center;
  flex-shrink: 0;
}

.caption-list-single input {
  display: none;
}

.caption-list-single input:checked+.caption-list-single-label {
  background-color: #000;
}

.caption-list-single-label {
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
}

.caption-list-single-label:hover {
  background-color: #000;
}

.caption-list-text {
  text-align: left;
}

.caption-list-languages {
  max-height: none;
  column-count: 3;
  column-gap: 22px;
}

.caption-list-input {
  margin-left: 0;
  accent-color: #000;
}

.markers-list {
  display: none;
  position: absolute;
  bottom: calc(100% + 25px);
  right: 10px;
  z-index: 1;
  background-color: #2b2b2b;
  color: #fff;
  max-width: 315px;
  max-height: 320px;
  overflow-y: auto;
}

.markers-list.visible {
  display: block;
}

.markers-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.markers-list ul li {
  padding: 8px 12px;
  display: flex;
  font-weight: 600;
}

.markers-list-time {
  white-space: nowrap;
}

.markers-list ul li:hover {
  background-color: #000;
  cursor: pointer;
}

.markers-list-text {
  margin-left: 12px;
  text-align: left;
}

.plyr__progress__container .plyr__tooltip {
  background-color: #000;
  color: #fff;
  font-size: 14px;
  max-width: none !important;
  width: fit-content;
}

.d-none {
  display: none;
}

.video-footer-title {
  font-size: 20px;
  font-weight: 600;
  margin: 5px 0 12px;
}

.video-footer-control-area {
  display: flex;
  flex-wrap: wrap;
}

.video-user-img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 12px;
}

.video-footer-btn-area {
  display: flex;
  align-items: center;
}

.video-user-name-area {
  margin-right: 24px;
}

.video-user-name {
  font-size: 16px;
  font-weight: 600;
}

.video-user-name-area-small {
  font-size: 12px;
}

.footer-btn {
  padding: 0 16px;
  height: 36px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.footer-btn:hover {
  background-color: #3f3f41;
}

.footer-btn svg {
  font-size: 20px;
}

.footer-btn,
.video-like-area {
  border-radius: 18px;
}

.subscribe-btn {
  background-color: #f1f1f1;
  color: #000;
  min-width: 103px;
}

.subscribe-btn:hover {
  background-color: #d9d9d9;
}

.subscribe-btn.active {
  animation: subscribe 1.5s;
  box-shadow: 0 0 0 10px transparent;
  background-image: none;
  background-color: var(--yellow-color);
}

@keyframes subscribe {
  0% {
    background-image: url(../images/sparkle.gif);
    box-shadow: 0 0 0 0 var(--yellow-color);
  }

  100% {
    background-image: url(../images/sparkle.gif);
  }
}

.video-like-btn {
  border-radius: inherit;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;
}

.like-animation-position {
  position: relative;
}

.like-animation-effect {
  position: absolute;
  left: -25px;
  top: -22px;
  width: 68px;
  height: auto;
  z-index: 1;
}

.video-like-btn:after {
  content: "";
  background-color: #454343;
  width: 1px;
  height: 68%;
  position: absolute;
  right: 0;
}

.video-dislike-btn {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.video-like-area {
  display: flex;
  align-items: center;
}

.video-footer-right-area {
  display: flex;
  align-items: center;
  gap: 10px;
}

.video-detail-box {
  padding: 12px;
  border-radius: 12px;
  margin-top: 15px;
  font-size: 14px;
}

.video-detail-box p {
  margin: 0 0 4px;
  font-weight: 500;
}

.featured-presenter {
  display: flex;
  gap: 22px;
  margin-bottom: 48px;
}

.featured-presenter:last-child {
  margin-bottom: 0;
}

.featured-presenter-data {
  flex-grow: 1;
}

.featured-presenter-data-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.featured-presenter-name {
  font-size: 14px;
  font-weight: 500;
}

.featured-presenter-profile {
  font-size: 13px;
}

.featured-presenter-img {
  width: 142px;
  height: 142px;
  flex-shrink: 0;
}

.featured-presenter-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.featured-presenter-logo {
  width: 34px;
}

.like-animation {
  animation: like 1s cubic-bezier(0.4, 0, 1, 1);
  color: var(--yellow-color);
}

@keyframes like {
  0% {
    transform: scale(0);
    color: #aabd45;
  }

  20% {
    transform: scale(1.1) rotate(-30deg);
    transform-origin: left bottom;
    color: #54ddfd;
  }

  40% {
    color: #fd8bfd;
  }

  70% {
    transform: scale(1);
    color: var(--yellow-color);
  }
}

.static-play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  border: none;
  background-color: transparent;
  width: 73px;
  height: 73px;
}

.static-play-button svg {
  font-size: 72px;
  color: #a4a8af;
  background-color: #000000ab;
  border-radius: 50%;
}

#inverstor-relations-demo-poster .plyr__poster {
  background-image: url("../images/investor-poster.png") !important;
}

#strategic-investor-relations-poster .plyr__poster {
  background-image: url("../images/strategic-investor-poster.png") !important;
}

/* videoHome Css */
.plan_card {
  height: 467px;
}

/* Upload Video Process Page CSS */

.choose-tab-padding {
  padding: 12px 20px;
}

.choose-tab-radius-left {
  border-radius: 5px 0 0 5px;
}

.choose-tab-radius-right {
  border-radius: 0 5px 5px 0;
}

.plan-card-btn {
  padding: 10px 18px;
}

.plan-card-inner {
  width: 280px;
}

.plan_card_height {
  height: 467px;
}

.embedded-container {
  padding: 45px 20px;
  width: 460px;
}

.embedded-container textarea {
  height: 90px;
  width: 90%;
  resize: none;
}

.embedded-container textarea:focus {
  outline: 1px solid var(--yellow-color);
}

.embedded-container .cross-icon {
  top: 15px;
  right: 20px;
  cursor: pointer;
}

.embedded-container button {
  width: 150px;
  padding: 12px 24px;
  border: 1px solid var(--yellow-color);
  background-color: #000;
  color: #ffff;
}

.embedded-container button:hover {
  color: var(--yellow-color);
}

@media (max-width: 1200px) {
  .click-for-sound-text {
    display: block;
  }

  .plyr__control--overlaid {
    border-width: 4px;
    height: 70px;
    width: 70px !important;
  }

  .plyr__control--overlaid svg {
    height: 26px;
    width: 26px;
  }

  .header-language-list-wrap.showMenuBar {
    left: 0px !important;
  }

  .choose-plan-container {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .loading {
    padding: 26px;
    border: 6px solid #ffffffde;
    border-right-color: transparent;
    border-radius: 50px;
    animation: loader 1s infinite linear;
  }

  .backdrop-fillter {
    backdrop-filter: none;
  }

  .embedded-container {
    width: 90%;
    padding: 35px 20px;
  }

  .embedded-container textarea {
    font-size: 12px;
  }

  .main-header {
    height: 60px;
  }

  .main-layout .video-wrap video {
    height: auto;
  }

  .markers-list {
    bottom: 100%;
    max-height: 130px;
  }

  .markers-list ul li {
    padding: 6px 8px;
    font-size: 12px;
  }

  .caption-list {
    max-height: 110px;
    bottom: 100%;
    max-width: 99vw;
    right: 0;
  }

  .video-frame-separate .caption-list {
    max-width: 99vw;
    right: 0;
    max-height: 240px;
  }

  .caption-list-single {
    font-size: 13px;
  }

  .caption-list-short-name {
    font-size: 10px;
    min-width: 30px;
  }

  .caption-list-single-label {
    padding: 5px 9px;
  }

  .caption-list-languages {
    column-count: 2;
    column-gap: 15px;
  }

  /* .video-frame-separate .caption-list-languages{
    column-count: 2;
    column-gap: 15px;
  } */
  .caption-list-input {
    width: 10px;
  }

  /* .video-frame-separate .caption-list-input{
    width: 10px;
  } */
  .main-layout .video-wrap {
    width: 100%;
  }

  /* .video-frame-separate .video-wrap video{
    height: 100vh;
  } */
  .plyr__progress__marker {
    border-width: 1px;
    width: 6px;
    height: 6px;
  }

  .plyr {
    line-height: normal;
  }

  .plyr__control--overlaid {
    border-width: 3px;
    height: 55px;
    width: 55px !important;
    /* aspect-ratio: 1/1; */
  }

  .plyr__caption {
    font-size: 10px;
  }

  .plyr__control--overlaid svg {
    width: 50%;
    height: 50%;
  }

  .click-for-sound-btn {
    padding: 0px 11px;
    height: 36px;
    font-size: 11px;
    top: 5px;
    right: 7px;
  }

  .click-for-sound img {
    width: 18px;
  }

  .plyr__control svg {
    width: 50%;
    height: 50%;
    min-width: 14px;
    min-height: 14px;
  }

  .plyr__control img {
    width: 14px;
  }

  .plyr__controls__item .plyr__control,
  button.plyr__controls__item {
    height: 36px;
    width: 24px;
    padding: 2px;
  }

  .plyr--video .plyr__controls {
    height: 40px;
  }

  .plyr__time {
    font-size: 10px;
  }

  .plyr--fullscreen-enabled .plyr__video-wrapper {
    display: flex;
    align-items: center;
  }

  .speed-control-area-num .x-btn,
  .speed-control-area {
    font-size: 11px;
  }

  .speed-control-area-row {
    padding: 4px 9px;
  }

  .speed-control-area {
    bottom: 36px;
  }

  .speed-control-area.speed-control-area-animated {
    bottom: 6px;
  }

  .share-area-btn-icon {
    width: 42px;
    height: 42px;
  }

  .share-area-btn-icon svg {
    font-size: 25px;
  }

  .main-layout-video-wraper {
    padding: 15px 0;
  }

  .video-footer-title {
    font-size: 16px;
  }

  .video-footer-control-area {
    row-gap: 20px;
  }

  .video-user-img {
    width: 30px;
    height: 30px;
  }

  .footer-btn {
    height: 29px;
    font-size: 12px;
    padding: 0 11px;
  }

  .video-footer-right-area {
    margin-left: 0;
    gap: 7px;
    width: 100%;
    overflow-y: auto;
  }

  .video-user-name {
    font-size: 14px;
  }

  .video-user-name-area-small {
    font-size: 10px;
  }

  .video-footer-btn-area {
    width: 100%;
  }

  .subscribe-btn {
    margin-left: auto;
  }

  .video-detail-box {
    font-size: 12px;
  }

  .footer-btn svg {
    font-size: 16px;
  }

  .featured-presenter-img {
    width: 50px;
    height: 50px;
  }

  p {
    font-size: 13px;
  }
}

::-webkit-scrollbar {
  background: #373737;
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #373737;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #575757;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}