@import "./variables.scss";

.videos {
    &-layout {
        height: 100vh;
        width: 100%;
        background-color: #000;
        box-sizing: border-box;

        &-content {
            width: 100%;
            min-height: 100vh;
            overflow-y: auto;
            padding-top: 67px;

            &-page {
                width: 100%;
                padding-top: 48px;
                padding-bottom: 48px;

                @media (max-width: 767px) {
                    padding-top: 28px;
                    padding-bottom: 28px;
                }

                &-nospace {
                    padding: 0px;

                    &-top {
                        padding-top: 0px;
                    }

                    &-bottom {
                        padding-bottom: 0px;
                    }
                }
            }

            &-container {
                max-width: 1130px;
                padding-left: 20px;
                padding-right: 20px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &-disclaimer {
        width: 100%;

        &-heading {
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 16px;
            color: #e9e9e9;
        }

        &-text {
            font-size: 12px;
            color: var(--hms-ui-colors-secondary);
        }
    }

    &-loader {
        color: #fff;
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;

        &-fixed {
            position: fixed;
            top: 0px;
            bottom: 0px;
            left: 0;
            right: 0;
            z-index: 999999999999999;
            background-color: rgb(33 37 41 / 60%);
            color: #fff;
        }

        &-dark {
            background-color: #000;
            backdrop-filter: blur(3px);
        }
    }

    &-uploadBox {
        position: relative;

        label {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: auto;
            border: 0;
            z-index: 1;
            min-width: 100%;

            .sc-gEvEer {
                border: 0px;
            }

            *:not(.sc-gEvEer) {
                display: none;
            }
        }

        &-withDrag {
            padding: 48px 48px;
            @include vendor-prefix(border-radius, 11px);
            border: 1px solid var(--hms-ui-colors-surface_bright);
            background: var(--hms-ui-colors-surface_dim);
            position: relative;

            &-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 24px;
                color: var(--hms-ui-colors-white);

                &-heading {
                    font-size: 24px;
                    font-weight: 700;

                    @media (max-width: 767px) {
                        font-size: 20px;
                    }
                }

                &-text {
                    font-size: 16px;
                }

                &-info {
                    font-size: 14px;
                    color: var(--hms-ui-colors-secondary);
                }
            }

            @media (max-width: 767px) {
                padding: 28px;
            }
        }

        &-withUrl {
            padding: 8px;
            @include vendor-prefix(border-radius, 11px);
            border: 1px solid var(--hms-ui-colors-surface_bright);
            background: var(--hms-ui-colors-surface_dim);
            display: flex;
            gap: 24px;
            align-items: center;

            @media (max-width: 767px) {
                gap: 12px;
            }

            &-col {
                position: relative;
                flex: 1;
            }

            &-input {
                padding: 0px;
                width: 100%;
                color: var(--hms-ui-colors-white);
                border: none;
                background: transparent;
                height: 40px;
                flex: 1;
                outline: none;
                padding-left: 10px;
            }
        }

        &-or {
            position: relative;
            text-align: center;
            color: var(--hms-ui-colors-gray);
            font-size: 14px;
            margin: 25px 0px;

            &-text {
                &-inner {
                    font-weight: 700;
                    background: var(--hms-ui-colors-surface_default);
                    position: relative;
                    padding-left: 10px;
                    padding-right: 10px;
                    z-index: 1;
                    font-size: 14px;
                }

                &::before,
                &::after {
                    content: "";
                    height: 1px;
                    background-color: var(--hms-ui-colors-secondary_default);
                    position: absolute;
                    top: 50%;
                    width: 45%;
                }

                &::before {
                    left: 0;
                }

                &::after {
                    right: 0;
                }
            }
        }
    }

    &-btn {
        @include vendor-prefix(border-radius, 8px !important);
        font-weight: 600 !important;
        box-shadow: none !important;
        padding: 8px 20px !important;

        &-primary {
            border-color: var(--hms-ui-colors-yellow) !important;
            color: var(--hms-ui-colors-white) !important;
            background-color: var(--hms-ui-colors-black) !important;

            &:hover {
                color: var(--hms-ui-colors-yellow) !important;

                svg {
                    path {
                        &[stroke] {
                            stroke: var(--hms-ui-colors-yellow);
                        }

                        &[fill] {
                            fill: var(--hms-ui-colors-yellow);
                        }
                    }
                }
            }
        }

        &-secondary {
            @include vendor-prefix(border-radius, 8px !important);
            border-color: var(--hms-ui-colors-secondary_default) !important;
            color: var(--hms-ui-colors-secondary) !important;

            &:hover {
                background-color: var(--hms-ui-colors-surfaceLighter) !important;
                border-color: var(--hms-ui-colors-surfaceLighter) !important;
                color: var(--hms-ui-colors-white) !important;
            }
        }

        &-dark {
            @include vendor-prefix(border-radius, 50px !important);
            border-color: var(--hms-ui-colors-dark) !important;
            color: var(--hms-ui-colors-light) !important;
            background-color: var(--hms-ui-colors-dark) !important;

            &:hover {
                border-color: var(--hms-ui-colors-dark-light) !important;
                background-color: var(--hms-ui-colors-dark-light) !important;
                color: var(--hms-ui-colors-white) !important;
            }
        }

        &-md {
            padding: 11px 30px !important;
        }

        &-sm {
            padding: 5px 15px !important;
            text-transform: none !important;
            font-weight: normal !important;
        }
    }

    &-dialog {
        .MuiPaper-root {
            background-color: var(--hms-ui-colors-black);
            border: 1px solid var(--hms-ui-colors-surfaceLighter);
            @include vendor-prefix(border-radius, 11px);
            color: var(--hms-ui-colors-white);
            padding: 15px;

            @media (max-width: 767px) {
                margin: 0;
                max-width: calc(100vw - 32px);
            }

            h2 {
                font-weight: 600;
                font-size: 20px;
                word-break: break-word;
            }
        }

        &-icon {
            svg {
                margin: 10px auto 0;
            }
        }

        &-action {
            justify-content: center !important;
            gap: 10px;
        }

        &-btn {
            min-width: 120px !important;
        }

        &-full {
            .MuiPaper-root {
                width: 100%;
            }
        }
    }

    &-embedBox {
        word-break: break-all;
        padding: 14px;
        @include vendor-prefix(border-radius, 5px);
        background-color: var(--hms-ui-colors-lighter);
        color: var(--hms-ui-colors-black);
        font-weight: 500;
        text-align: left;
    }

    &-share {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        justify-content: center;

        &-btn {
            border: none;
            padding: 0;
            cursor: pointer;
            text-decoration: none;
            background: transparent;

            &-icon {
                width: 50px;
                height: 50px;
                @include vendor-prefix(border-radius, 50%);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                font-size: 26px;

                @media (max-width:767px) {
                    width: 40px;
                    height: 40px;
                    font-size: 22px;
                }



                &.embed {
                    background-color: #000;
                }

                &.whatsapp {
                    background-color: #25d366;
                }

                &.twitter {
                    background-color: #1da1f2;
                }

                &.facebook {
                    background-color: #3b5998;
                }

                &.linkedin {
                    background-color: #0077b5;
                }
            }

            &-label {
                font-size: 12px;
                color: var(--hms-ui-colors-lighter);
                margin-top: 10px;
                display: block;
            }

            &:hover & {
                &-label {
                    color: var(--hms-ui-colors-yellow);
                }
            }
        }

        &-url {
            display: flex;
            align-items: center;
            color: var(--hms-ui-colors-white);
            background-color: var(--hms-ui-colors-black);
            border: 1px solid var(--hms-ui-colors-surface_bright);
            @include vendor-prefix(border-radius, 8px);
            font-size: 13px;
            justify-content: space-between;
            padding: 10px 7px 10px 16px;
            margin-top: 30px;
            gap: 10px;

            &-text {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    &-subscribe {
        @include vendor-prefix(border-radius, 50px);
        border-color: var(--hms-ui-colors-lighter);
        color: var(--hms-ui-colors-black);
        background-color: var(--hms-ui-colors-lighter);
        padding: 0 16px;
        height: 36px;
        font-size: 14px;
        cursor: pointer;
        border: none;

        &:hover {
            background-color: var(--hms-ui-colors-light);
        }

        &.active {
            animation: videosubscribe 1.5s;
            box-shadow: 0 0 0 10px transparent;
            background-image: none;
            background-color: var(--hms-ui-colors-yellow);
        }
    }

    &-like {
        background-color: rgb(30, 30, 30);
        @include vendor-prefix(border-radius, 18px);
        display: flex;
        align-items: center;

        &-btn {

            color: var(--hms-ui-colors-lighter);
            background-color: var(--hms-ui-colors-dark);
            padding: 0 16px;
            height: 36px;
            font-size: 14px;
            cursor: pointer;
            border: none;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            svg {
                font-size: 20px
            }

            &-like {
                @include vendor-prefix(border-radius, 50px 0px 0px 50px);

                &::after {
                    content: "";
                    background-color: #3f3f41;
                    width: 1px;
                    height: 68%;
                    position: absolute;
                    right: 0;
                }
            }

            &-dislike {
                @include vendor-prefix(border-radius, 0px 50px 50px 0px);
            }

            &-icon {
                &-position {
                    position: relative;
                }
            }

            &:hover {
                background-color: var(--hms-ui-colors-dark-light);
            }

            &-position {
                position: relative;
            }

            &-animation {
                animation: videoslike 1s cubic-bezier(0.4, 0, 1, 1);
                color: var(--hms-ui-colors-yellow);
            }

            &-effect {
                position: absolute;
                left: -25px;
                top: -22px;
                width: 68px;
                height: auto;
                z-index: 1;
            }
        }

    }

    &-creator {
        display: flex;
        gap: 10px;
        align-items: center;

        &-img {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--hms-ui-colors-secondary_default);
            border: 1px solid #303740;
            @include vendor-prefix(border-radius, 50%);
            overflow: hidden;
            flex: none;

            img {
                height: auto;
                max-width: 100%;
                object-fit: cover;
            }
        }

        &-info {
            &-title {
                font-size: 16px;
                font-weight: 700;
                color: #e9e9e9;
            }

            &-text {
                font-size: 14px;
                color: var(--hms-ui-colors-secondary);
            }
        }
    }
}

.videosPopup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999999;
    background: rgba(19, 19, 19, 0.7);
    backdrop-filter: blur(10px);
    overflow: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;

    &-close {
        position: absolute;
        cursor: pointer;
        right: 0px;
        top: 0;
        padding: 10px 15px;
    }

    &-inner {
        position: relative;
        color: #fff;
        gap: 10px;
        width: 1000px;
        background: #2b2b2b;
        border: 1px solid #454648;
        border-radius: 6px;
        padding: 24px 24px;
        max-width: 95%;
        margin: auto auto;
    }

    &-sm & {
        &-inner {
            width: 500px;
        }
    }

    &-xs & {
        &-inner {
            width: 400px;
        }
    }

    &-md & {
        &-inner {
            width: 700px;
        }
    }

    &-header {
        &-heading {
            color: #e9e9e9;
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            padding-right: 20px;
            line-height: 130%;

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }

        &-desc {
            color: var(--hms-ui-colors-secondary);
            font-size: 14px;
            margin-top: 10px;
            text-align: center;
        }
    }

    &-content {
        width: 100%;
        padding: 20px 0px;
    }

    &-footer {
        &-btn {
            gap: 16px;

            &.btn-grey {
                width: 194px;
                height: 48px;
                color: #fff;
                border: 1px solid #454648;
                border-radius: 6px;

                &:active {
                    background-color: #373737;
                }
            }

            &.btn-gold {
                width: 194px;
                height: 48px;
                color: #fff;
                border: 1px solid #e3c050;
                border-radius: 6px;
                background-color: #121212;
            }
        }
    }
}

.Toastify {
    &__toast {
        background: #2b2b2b !important;
        border: 1px solid #454648;
    }
}

.w {
    &-auto {
        width: auto;
    }

    &-50 {
        width: 50%;
    }

    &-100 {
        width: 100%;
    }
}

.mt {
    &-auto {
        margin-top: auto !important;
    }

    &-0 {
        margin-top: 0px !important;
    }

    &-5 {
        margin-top: 5px !important;
    }

    &-10 {
        margin-top: 10px !important;
    }

    &-15 {
        margin-top: 15px !important;
    }

    &-20 {
        margin-top: 20px !important;
    }
}

.mb {
    &-auto {
        margin-bottom: auto !important;
    }

    &-0 {
        margin-bottom: 0px !important;
    }

    &-5 {
        margin-bottom: 5px !important;
    }

    &-10 {
        margin-bottom: 10px !important;
    }

    &-15 {
        margin-bottom: 15px !important;
    }

    &-20 {
        margin-bottom: 20px !important;
    }
}

.ml {
    &-auto {
        margin-left: auto !important;
    }

    &-0 {
        margin-left: 0px !important;
    }

    &-5 {
        margin-left: 5px !important;
    }

    &-10 {
        margin-left: 10px !important;
    }

    &-15 {
        margin-left: 15px !important;
    }

    &-20 {
        margin-left: 20px !important;
    }
}

.mr {
    &-auto {
        margin-right: auto !important;
    }

    &-0 {
        margin-right: 0px !important;
    }

    &-5 {
        margin-right: 5px !important;
    }

    &-10 {
        margin-right: 10px !important;
    }

    &-15 {
        margin-right: 15px !important;
    }

    &-20 {
        margin-right: 20px !important;
    }
}

.mx {
    &-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.text {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }

    &-underline {
        text-decoration: underline;
    }

    &-primary {
        color: var(--hms-ui-colors-yellow);
    }

    &-light {
        color: var(--hms-ui-colors-light);
    }

    &-white {
        color: var(--hms-ui-colors-white);
    }

    &-link {
        &:hover {
            color: var(--hms-ui-colors-yellow);
        }
    }
}

@keyframes videosubscribe {
    0% {
        background-image: url("../images/sparkle.gif");
        box-shadow: 0 0 0 0 var(--hms-ui-colors-yellow);
    }

    100% {
        background-image: url("../images/sparkle.gif");
    }
}

@keyframes videoslike {
    0% {
        transform: scale(0);
        color: #aabd45;
    }

    20% {
        transform: scale(1.1) rotate(-30deg);
        transform-origin: left bottom;
        color: #54ddfd;
    }

    40% {
        color: #fd8bfd;
    }

    70% {
        transform: scale(1);
        color: var(--hms-ui-colors-yellow);
    }
}